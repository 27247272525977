import { Grid, Link } from '@bottlebooks/gatsby-design-system';
import React from 'react';

export default function Speakers({ speakers }) {
  return (
    <Grid columns={[1, 1, 3, 3]}>
      {speakers.map((speaker) => {
        return (
          <Link to={`/speakers/${speaker.slug}`}>
            <img src={speaker.custom.promotionalImageUrl} width="100%" />
          </Link>
        );
      })}
    </Grid>
  );
}
