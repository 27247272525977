/** @jsx jsx */
import {
  Box,
  Grid,
  Flex,
  Link,
  Text,
  Title,
} from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';
import Section from '../Layout/Section';

export default function MainEventSection({
  id = 'mainEventSection',
  title,
  subtitle,
  currentEvent,
  events,
  ...rest
}) {
  return (
    <Section id={id} {...rest}>
      <Box sx={{ textAlign: 'center', marginBottom: 5 }}>
        <Title sx={{ fontWeight: 700, fontSize: 36 }}>
          {currentEvent.title}
        </Title>
        <Text>{currentEvent.subtitle}</Text>
      </Box>
      <Grid
        gap={3}
        width={300}
        sx={{ gridColumn: ['1 / -1', '2 / -1'], marginBottom: 5 }}
      >
        <Box>
          <img src={currentEvent.imageUrl} sx={{ width: '100%' }} />
        </Box>
        <Box>
          <Text>{currentEvent.description}</Text>
        </Box>
      </Grid>

      {events && (
        <Box sx={{ textAlign: 'center', marginBottom: 5 }}>
          <Title sx={{ fontWeight: 600, fontSize: 28 }}>Other events</Title>
          <Text>These related events are happening in other cities.</Text>
        </Box>
      )}

      <Grid
        gap={3}
        width={[300, 300, 200, 200]}
        sx={{ gridColumn: ['1 / -1', '2 / -1'] }}
      >
        {events?.map((event) => (
          <EventItem {...event} />
        ))}
      </Grid>
    </Section>
  );
}

function EventItem({ title, subtitle, url, imageUrl, ...rest }) {
  return (
    <Link href={url}>
      <Box
        sx={{
          position: 'relative',
          color: 'rgb(0, 59, 92)',
          '&:hover': {
            color: 'primary',
          },
        }}
      >
        <img src={imageUrl} sx={{ width: '100%' }} />
        <Text
          sx={{
            textTransform: 'uppercase',
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </Box>
    </Link>
  );
}
