import { Flex, Grid, Link } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import lo from 'lodash';
import React from 'react';

// TODO for now the variants are embedded here - we can move them to the theme later.
const variants = {
  default: {
    variant: 'text.small',
    borderRadius: 'round',
    border: 1,
    paddingX: 3,
    paddingY: 1,
    marginBottom: 3,
    marginRight: 3,
  },
  small: {
    variant: 'text.smallest',
    borderRadius: 'round',
    border: 1,
    paddingX: 3,
    paddingY: '3px',
    marginBottom: 1,
    marginRight: 1,
  },
};

export default function Regions({ regions }) {
  const [selectedGrapeVariety, setSelectedGrapeVariety] = React.useState();
  const varieties = regions.reduce((acc, region) => {
    acc = acc.concat(region.grapeVarieties);
    return acc;
  }, []);
  const uniqueVarieties = lo.uniq(varieties).sort();
  const filteredRegions = selectedGrapeVariety
    ? regions.filter((region) =>
        region.grapeVarieties.includes(selectedGrapeVariety)
      )
    : regions;
  return (
    <React.Fragment>
      <Flex sx={{ flexWrap: 'wrap', marginBottom: 3 }}>
        <Button
          variant={undefined === selectedGrapeVariety ? 'primary' : 'default'}
          onClick={() => setSelectedGrapeVariety(undefined)}
          sx={{
            ...variants.small,
            ...(undefined === selectedGrapeVariety
              ? {
                  backgroundColor: 'primary',
                  color: 'white',
                }
              : {}),
          }}
        >
          Alle Regionen
        </Button>
        {uniqueVarieties.map((variety) => (
          <Button
            key={variety}
            variant={variety === selectedGrapeVariety ? 'primary' : 'default'}
            onClick={() => setSelectedGrapeVariety(variety)}
            sx={{
              ...variants.small,
              ...(variety === selectedGrapeVariety
                ? {
                    backgroundColor: 'primary',
                    color: 'white',
                  }
                : {}),
            }}
          >
            {variety}
          </Button>
        ))}
      </Flex>
      <Grid columns={[1, 1, 3, 3]}>
        {filteredRegions.map((region) => (
          <Link key={region.slug} to={`/regionen/${region.slug}`}>
            <img src={region.custom.promotionalImageUrl} width="100%" />
          </Link>
        ))}
      </Grid>
    </React.Fragment>
  );
}
