import {
  Box,
  Flex,
  Grid,
  Label,
  Link,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import React from 'react';
import { Section, Slider } from '.';

export default function RegionPage({ region }) {
  // This is necessary until we loading regions via GraphQL
  // This is currently called by a client-side route
  if (!region)
    return (
      <Box>
        <Text>No region data for this slug</Text>
      </Box>
    );
  return (
    <React.Fragment>
      <Slider
        imageUrl={region.mainImageUrl}
        title={region.custom.title}
        height={[325, 450, 525, 600]}
      />
      <Section>
        <Section.Body sx={{ paddingTop: 3 }}>
          <Grid columns={[1, 1, 2, 2]} gap={4}>
            <Box>
              <img src={region.custom.promotionalImageUrl} width="100%" />
              <Text>{region.custom.shortDescription}</Text>
            </Box>
            <Box>
              <Section.Title sx={{ marginBottom: 3 }}>
                {region.name} im Überblick
              </Section.Title>
              {region.characteristics.map((characteristic) => (
                <Box sx={{ marginBottom: 3 }}>
                  <Label>{characteristic.title}</Label>
                  <Text>{characteristic.description}</Text>
                </Box>
              ))}
            </Box>
          </Grid>
        </Section.Body>
      </Section>
      <Slider
        imageUrl={region.custom.backgroundImageUrl}
        parallax={true}
        height={[130, 200, 300, 368]}
      />
      <Section>
        <Section.Body sx={{ paddingTop: 3 }}>
          <Flex direction={['column', 'column', 'row', 'row']}>
            <Box
              sx={{
                width: ['100%', '100%', '65%', '65%'],
                paddingRight: [0, 0, 3, 3],
                flexShrink: 0,
              }}
            >
              <Section.Title sx={{ marginBottom: 3 }}>
                {region.custom.title2}
              </Section.Title>
              <Text>{region.description}</Text>
            </Box>
            <Box>
              <img src={region.mapImageUrl} width="100%" />
              <SemiTitle>{region.association.name}</SemiTitle>
              <Box sx={{ marginTop: 3 }}>
                <Text>{region.association.street}</Text>
                <Text>
                  {region.association.postalCode} {region.association.city}
                </Text>
              </Box>
              <Box sx={{ marginY: 3 }}>
                <Text>Tel: {region.association.telephone}</Text>
                <Text>Tel: {region.association.fax}</Text>
              </Box>
              <Box sx={{ marginY: 3 }}>
                <Link href={region.association.website}>
                  <Text>{region.association.websiteText}</Text>
                </Link>
                <Link href={region.association.email}>
                  <Text>
                    {region.association?.emailText
                      ?.replace('@', '[at]')
                      ?.replaceAll('.', '[dot]')}
                  </Text>
                </Link>
              </Box>
              <Box sx={{ marginY: 3 }}>
                <Text>
                  Weitere Informationen zur Region erhalten Sie durch das
                  Informationsangebot des Deutschen Weininstituts
                </Text>
              </Box>
              <Button
                variant="primary"
                href={region.custom.externalLink}
                target="_blank"
              >
                Mehr erfahren
              </Button>
            </Box>
          </Flex>
        </Section.Body>
      </Section>
    </React.Fragment>
  );
}
