/** @jsx jsx */
import {
  Box,
  Grid,
  Link,
  Text,
  Title,
} from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';
import Section from '../Layout/Section';

export default function EventsSection({
  id = 'eventsSection',
  title,
  subtitle,
  events,
  ...rest
}) {
  return (
    <Section id={id} {...rest}>
      <Box sx={{ textAlign: 'center', marginBottom: 5 }}>
        <Title sx={{ fontWeight: 700, fontSize: 36 }}>{title}</Title>
        <Text>{subtitle}</Text>
      </Box>
      <Grid gap={3} width={300} sx={{ gridColumn: ['1 / -1', '2 / -1'] }}>
        {events.map((event) => (
          <EventItem {...event} />
        ))}
      </Grid>
    </Section>
  );
}

function EventItem({ title, subtitle, url, imageUrl, ...rest }) {
  return (
    <Link href={url}>
      <Box
        sx={{
          position: 'relative',
          color: 'rgb(0, 59, 92)',
          '&:hover': {
            color: 'primary',
          },
        }}
      >
        <img src={imageUrl} sx={{ width: '100%' }} />
        <Text
          sx={{
            textTransform: 'uppercase',
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </Box>
    </Link>
  );
}
