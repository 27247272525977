import {
  Box,
  Link,
  P,
  SemiTitle,
  Text,
  Title,
} from '@bottlebooks/gatsby-design-system';
import { MDXProvider } from '@mdx-js/react';
import React from 'react';

/**
 * React helper to map MDX files on to Bottlebooks design system
 * @param {*} param0
 * @returns
 */
export default function MDXFormattedText({ FormattedText, ...rest }) {
  return (
    <Box {...rest}>
      <Text>
        <MDXProvider
          components={{
            h1: ({ children }) => (
              <Box sx={{ marginBottom: 2 }}>
                <Title>{children}</Title>
              </Box>
            ),
            h2: ({ children }) => (
              <Box sx={{ marginBottom: 1 }}>
                <Title variant="small">{children}</Title>
              </Box>
            ),
            h3: ({ children }) => (
              <Box sx={{ marginBottom: 3 }}>
                <SemiTitle>{children}</SemiTitle>
              </Box>
            ),
            h4: ({ children }) => <SemiTitle>{children}</SemiTitle>,
            h5: ({ children }) => (
              <SemiTitle variant="small" sx={{ textTransform: 'uppercase' }}>
                {children}
              </SemiTitle>
            ),
            h6: ({ children }) => (
              <SemiTitle variant="smallest">{children}</SemiTitle>
            ),
            p: ({ children }) => <P>{children}</P>,
            a: ({ href, children, ...rest }) => {
              return href.includes('http') ? (
                <Link href={href} {...rest}>
                  {children}
                </Link>
              ) : (
                <Link to={href} {...rest}>
                  {children}
                </Link>
              );
            },
          }}
        >
          <FormattedText />
        </MDXProvider>
      </Text>
    </Box>
  );
}
